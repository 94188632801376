
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CharacterProfile"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Character"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"profile_sections"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"columns"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"row_order"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"widgets"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WidgetFields"},"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":238}};
    doc.loc.source = {"body":"#import \"graphql/fragments/WidgetFields.graphql\"\n\nfragment CharacterProfile on Character {\n    profile_sections {\n        id\n        title\n        columns\n        row_order\n        widgets {\n            ... WidgetFields\n        }\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("graphql/fragments/WidgetFields.graphql").definitions));


      module.exports = doc;
    
