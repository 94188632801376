
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CharacterTheme"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Character"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"theme"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"colors"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"primary"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"accent1"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"accent2"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"background"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cardBackground"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"imageBackground"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"text"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"textLight"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"textMedium"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":310}};
    doc.loc.source = {"body":"fragment CharacterTheme on Character {\n    theme {\n        id\n        name\n        colors {\n            primary\n            accent1\n            accent2\n            background\n            cardBackground\n            imageBackground\n            text\n            textLight\n            textMedium\n        }\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
