
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConversationsFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Conversation"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"guid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unreadCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastMessage"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_self"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"username"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"avatar_url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_admin"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_patron"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":299}};
    doc.loc.source = {"body":"fragment ConversationsFields on Conversation {\n    id\n    guid\n    unreadCount\n    lastMessage {\n        message\n        created_at\n        is_self\n        user {\n            name\n        }\n    }\n    user {\n        name\n        username\n        avatar_url\n        is_admin\n        is_patron\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
