import View from './static/View'
import NotFound from './static/NotFound'
import Home from './static/Home'

const Static = {
  Home,
  NotFound,
  View,
}

export default Static
